import { css } from "@emotion/react"

export const navBar = css`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  min-height: 112px;
  max-height: 112px;
  height: 100%;
  background: #FFB800;
  font-family: 'Source Sans Pro', sans-serif;
  align-items: center;
  /* box-shadow: 0 0 9px 0 rgb(0 0 0 / 30%); */
`

export const navBar2 = css`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  min-height: 112px;
  max-height: 112px;
  height: 100%;
  background: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  align-items: center;
  /* box-shadow: 0 0 9px 0 rgb(0 0 0 / 30%); */
`

export const navBar3 = css`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  min-height: 112px;
  max-height: 112px;
  height: 100%;
  background: #5416FF;
  font-family: 'Source Sans Pro', sans-serif;
  align-items: center;
  /* box-shadow: 0 0 9px 0 rgb(0 0 0 / 30%); */
`

export const navBar4 = css`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  min-height: 112px;
  max-height: 112px;
  height: 100%;
  background: #5315FF;
  font-family: 'Montserrat';
  align-items: center;
  /* box-shadow: 0 0 9px 0 rgb(0 0 0 / 30%); */
`

export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
  height: 100%;
  align-items: center;
`
export const logo = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 0px) and (max-width: 1317px) {
    margin-left: 10px;
  }
`
export const navBarMenu = css`
  display: flex;
  height: 100%;
  min-height: 80px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
  & > a {
    color: #5763ad;
  }
  div:not(:last-child), a:not(:last-child) {
    margin-right: 0px;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: none
  }
  @media (min-width: 0px) and (max-width: 960px) {
    background: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
  }
`
export const btn = css`
  border-radius: 30px;
  color: #fff;
  height: 44px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  flex-direction: inherit;
  justify-content: center;
  box-sizing: border-box;
  text-transform: uppercase;
  img {
    margin-right: 8px;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    border-radius: 0px;
    margin-left: 0px;
    width: 100%;
  }
  
`
export const SingUpBtn = css`
  ${btn}
  width: 134px;
  background: #206FB3;
  border-radius: 10px;
  color: #fff !important;
  align-items: center;
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 5px 10px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 10%);
    color: #fff !important;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    font-size: 30px;
  }
`
export const LoginBtn = css`
  ${btn}
  width: 86px;
  background: #206FB3;
  border-radius: 10px;
  color: #fff !important;
  align-items: center;
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 5px 10px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 10%);
  }
  @media (min-width: 0px) and (max-width: 960px) {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    font-size: 30px;
  }
`
export const GoToDashboard = css`
  ${btn}
  background-color: #4285F4;
  border-radius: 0 0 5px 0;
  :hover {
    background-color: #6F9CEB;
    color: #fff;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
export const navMenu = css`
  margin-left: auto;
  align-items: center;
  font-family: 'Dosis', sans-serif;
`
export const mobileMenu = css`
  & > div:nth-child(1) {
    display: flex;
    margin: 25px;
    cursor: pointer;
  }
  & > div:nth-child(2) {
    background: #5416FF;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    color: #375F92 !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 19px;
    cursor: pointer;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.30);
    div {
      padding: 12px 0 12px 0;
    }
  }
`
export const togle = css`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  color: #ffffff !important;
  div:hover, a:hover {
    color: #EF7B8E;
    transition: color 0.25s ease-out;
  }
  a {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #5416FF;
    display: flex;
    align-items: center;
    font-feature-settings: 'kern' off;
    :hover {
      color: white;
      transition: color 0.25s ease-out;
    }
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    a {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 32px;
      color: #F8F8F8;
      border-top: 1px solid rgba(234, 234, 234, 0.5);
      border-bottom: 1px solid rgba(234, 234, 234, 0.5);
      width: 100%;
    }
  }
`

export const togle2 = css`
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  color: #ffffff !important;
  div:hover, a:hover {
    color: #EF7B8E;
    transition: color 0.25s ease-out;
  }
  a {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #F8F8F8;
    display: flex;
    align-items: center;
    font-feature-settings: 'kern' off;
    :hover {
      color: white;
      transition: color 0.25s ease-out;
    }
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    a {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 32px;
      color: #F8F8F8;
      border-top: 1px solid rgba(234, 234, 234, 0.5);
      border-bottom: 1px solid rgba(234, 234, 234, 0.5);
      width: 100%;
    }
  }
`

export const togle3 = css`
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  color: #ffffff !important;
  div:hover, a:hover {
    color: #EF7B8E;
    transition: color 0.25s ease-out;
  }
  a {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    display: flex;
    align-items: center;
    font-feature-settings: 'kern' off;
    :hover {
      color: white;
      transition: color 0.25s ease-out;
    }
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    a {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 32px;
      word-break: break-word;
      color: #F8F8F8;
      border-top: 1px solid rgba(234, 234, 234, 0.5);
      border-bottom: 1px solid rgba(234, 234, 234, 0.5);
      width: 100%;
    }
  }
`

export const linkTo = css`
  color: #000000 !important;
  :hover {
    color: #206FB3 !important;
    transition: color 0.25s ease-out;
  }
`
export const socialIcons = css`
  margin-left: 0;
  @media (min-width: 0px) and (max-width: 960px) {
    a {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  display: flex;
  @media (min-width: 480px) {
    display: none;
  }
`

export const btnMobile = css`
  width: 90% !important;
  margin: 32px 0px !important;
`

export const headerMobile = css`
  display: flex;
  align-items: center;
  padding-left: 16px !important;
  padding-right: 16px !important;
  a {
    border: none;
  }
`